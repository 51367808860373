import Head from 'next/head'

import { CallToAction } from '@/components/CallToAction'
import { Faqs } from '@/components/Faqs'
import { Footer } from '@/components/Footer'
import { Header } from '@/components/Header'
import { Hero } from '@/components/Hero'
import { Pricing } from '@/components/Pricing'
import { PrimaryFeatures } from '@/components/PrimaryFeatures'
import { SecondaryFeatures } from '@/components/SecondaryFeatures'
import { Testimonials } from '@/components/Testimonials'
import {Team} from "@/components/Team";
import ContactInfo from "@/components/ContactInfo";
import HeaderSimple from "@/components/HeaderSimple";
import {NextSeo} from "next-seo";

export default function Custom404() {
    return (
        <>
            <NextSeo
                title="404"
                noindex
            />
            <Header />
            <main>
                <HeaderSimple
                    top_title="404"
                    title="Pagina niet gevonden"
                    text="Sorry, we hebben de pagina niet kunnen vinden."
                />
                <CallToAction />
            </main>
            <Footer />
        </>
    )
}
